.logo {
  background-image: url('../image/logo.png');
}

.add-to-cart {

  .number-input {
    float: left;
    width: 40%;
  }

  &__button {
    width: 60%;
    float: left;
  }
}
